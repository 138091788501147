import Vue from "vue";
import display from "@/configure/display/core/company";
import {CompanyEntity, CompanyQuery} from "@/core/entity/core/company";
import {DataOptions, DataTableHeader} from "vuetify";
import service from "@/core/service";
import tip from "@/configure/tip";

export default Vue.extend({
    data: () => ({
        show: true,
        loading: false,
        headers: <DataTableHeader[]>[
            {text: display.name, value: 'name'},
            {text: display.email, value: 'email'},
            {text: display.addr, value: 'addr'},
            {text: display.tel, value: 'tel'},
            {text: display.remark, value: 'remark'},
            {text: display.operTime, value: 'operTime'},
            {text: display.operOperatorName, value: 'operOperatorName'},
            {
                text: '',
                value: 'action',
                cellClass: ["d-flex", "justify-space-around", "align-center"],
                sortable: false,
                width: 140,
                align: 'center'
            },
        ],
        options: <DataOptions><unknown>{sortBy: [], sortDesc: [], page: 1, itemsPerPage: 10},
        data: <CompanyEntity>{},
        result: <CompanyQuery[]>[],
        resultLength: 0,
        datetime: "",
        display
    }),
    methods: {
        async query() {
            this.loading = true;
            try {
                const result = await service.company.query(this.$queryData(this.data, this.options, ["name"]));
                this.result = result;
                this.resultLength = result.dataLength!;
            } finally {
                this.loading = false;
            }
        },
        async add() {
            await this.$router.push({path: "/company/add"});
        },
        async load(key: number) {
            await this.$router.push({path: "/company/load/" + key});
        },
        async edit(key: number) {
            await this.$router.push({path: "/company/edit/" + key});
        },
        async del(key: number) {
            if (await this.$confirm(tip.confirm.del)) {
                await service.port.del(key);
                await this.query();
            }
        },
    }
});
